.branded-login {
    background-color: #082f49;
}


.branded-login .stepper-title {
    color: #fff;
}


.stepper.stepper-pills .stepper-item.current .stepper-label .stepper-title {
    color: #fff;
}

.stepper.stepper-pills .stepper-item.completed .stepper-label .stepper-title {
    color: #e5e5e5;
}

.stepper.stepper-pills .stepper-item .stepper-label .stepper-title {
    color: #e5e5e5;
}


.stepper.stepper-pills .stepper-item .stepper-icon .stepper-number {
    color: #082f49 !important;
}

.stepper.stepper-pills .stepper-item.current .stepper-icon {
    background-color: #0ea5e9;
}

.stepper-label .stepper-desc {
    color: #e5e5e5 !important;
}

.text-primary {
    color: #0ea5e9 !important
}

.btn-connect-shopify {
    color: #95BF46;
    border: solid 1px #95BF46 !important;
    background-color: transparent;
    margin-bottom: 100px;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.btn-connect-shopify:hover {
    color: white;
    background-color: #95BF46;
}

.content {
    padding: 0 !important;
}

.bg-primary {
    background-color: #0ea5e9 !important
}

.current-vat-period {
    padding-bottom: 15px !important;
}

.aside.aside-dark {
    background-color: #082f49;
}

.aside.aside-dark .aside-logo {
    background-color: #082f49;
}

.aside-dark .menu .menu-item .menu-link.active,
.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
    background-color: #052438;
}

.aside-dark .menu .menu-item .menu-link {
    color: #dcdcdc;
}

.aside-dark .menu .menu-item .menu-link .menu-title {
    color: #ffffff;
}

.aside-dark .menu .menu-item .menu-section {
    color: #fff !important;
}

.aside.aside-dark .btn-custom {
    color: #082f49;
    background-color: #fff;
}

.aside.aside-dark .btn-custom:hover:not(.btn-active) {
    color: #fff;
    background-color: #a7cacc !important;
}

.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: #ffffff !important;
}

.bg-primary.hoverable:hover {
    background-color: #082f49 !important;
}

.badge-info {
    color: #fff;
    background-color: #1754a2;
}

.btn-primary:hover,
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active),
.btn-primary:active {
    color: #fff;
    background-color: #082f49 !important;
    border-color: #082f49;
}

.modal-shopify .text-success {
    color: #95BF46 !important;
}

.modal-shopify .btn-success {
    background-color: #95BF46 !important;
}

.bank-account {
    background-color: rgb(30, 26, 20) !important;
}

.bank-account-not-connected {
    opacity: 0.3;
}

/* Start TABLE Styles */
.ReactTable {
    border: none !important;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
    background: #082f49;
    /*color: #f1faff;*/
}

/*.Table__pagination {*/
/*  color: #5e6278;*/
/*}*/

.rt-td {
    color: #6c7293;
    padding: 0;
}

.rt-tr-group:hover {
    background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
    box-shadow: none !important;
}

.rt-thead .rt-resizable-header-content {
    color: #B5B5C3 !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.rt-thead .-sort-asc .rt-resizable-header-content,
.rt-thead .-sort-desc .rt-resizable-header-content {
    color: #082f49 !important;
}

.rt-thead .rt-th {
    padding: 0.75rem 1rem !important;
}

.-sort-asc .rt-resizer::before {
    content: "\2191";
    color: #082f49;
    vertical-align: middle;
    font-weight: 600;
}

.-sort-desc .rt-resizer::after {
    content:"\2193";
    color: #082f49;
    vertical-align: middle;
    font-weight: 600;
}

.ReactTable .rt-resizer {
    top: 5px;
}

.ReactTable .rt-td {
    align-self: center;
}

.Table__page .select-page:hover {
    background-color: #082f49;
}

.rt-resizable-header-content {
    color: #595d6e !important;
    font-weight: 500;
}

.ReactTable .rt-expander:after {
    border-top: 7px solid #082f49 !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border: 0 !important;
}

.ReactTable .rt-thead.-header {
    padding-bottom: 0 !important;
}

.ReactTable .rt-tr{
    padding-top: unset !important;
    padding-bottom: unset !important;
    overflow-x: scroll;
}

.ReactTable .rt-tbody .rt-td {
    border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
    border-radius: 50% !important;
    font-size: unset !important;
}
/* End TABLE Styles */

.google-suggestion-item {
    cursor: pointer;
    padding: 5px;
    padding-left: 8px;
    font-size: 12px;
    margin: 0px;
}

.google-suggestion-item:hover {
    background-color: #f5f5f5;
}

.google-suggestion-list {
    box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
    color: #FA6160;
    text-decoration: underline;
}

/* starts, c-selectbox styling */
.c-selectbox{
    padding-left: 0 !important;
}
.c-selectbox div[class$="-control"] {
    border: 0;
    box-shadow: none;
    padding-left: 18px;
}

.c-selectbox{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0.42rem !important;
}
.c-selectbox div[class$="-control"] {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding-left: 3px;
}
.c-selectbox div[class$="-ValueContainer"] {
    padding: 0 10px !important;
}
.c-selectbox div[class$="-placeholder"] {
    color:#B5B5C3;
}

.login-form .c-selectbox{
    padding-bottom: 0.65rem !important;
    padding-top: 0.65rem !important;
    border-radius: 0.85rem !important;
}
.login-form .c-selectbox div[class$="-control"] {
    padding: 6px;
}
/* end, c-selectbox styling */

/* end, c-selectbox styling */

/* starts, c-branding-settings */
.c-branding-settings .background-image .image-input-wrapper{
    background-position: center;
}
/* end, c-branding-settings */

.btn-outline-primary:hover {
    color: #ffffff;
}

.Toastify__toast--success {
    background: #0ea5e9 !important
}

.spinner {
    position: relative;
}

.spinner:before {
    content: "";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    border: 2px solid #D1D3E0;
    border-right: 2px solid transparent;
}

.spinner:before {
    width: 5rem;
    height: 5rem;
    margin-top: -1rem;
}

.spinner:before {
    -webkit-animation: animation-spinner 0.5s linear infinite;
    animation: animation-spinner 0.5s linear infinite;
}

.spinner.spinner-primary:before {
    border: 2px solid #3699FF;
    border-right: 2px solid transparent;
}

.spinner:before {
    -webkit-animation: animation-spinner 0.5s linear infinite;
    animation: animation-spinner 0.5s linear infinite;
}

@-webkit-keyframes animation-spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animation-spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.ReactPasswordStrength {
    border: unset !important;
}

.ReactPasswordStrength-input {
    width: 100% !important;
    font-size: unset;
}

.ReactPasswordStrength-strength-desc {
    width: unset !important;
    text-transform: capitalize;
}

.dropdown-item{
    padding: unset !important;
}

/* starts, files preview section */

.modal-90vw {
    width: 90vw;
    max-width: 90vw;
}

.modal-90vw .modal-content {
    background: none;
    border: none;
}

@media (min-width: 768px) {
    .files-preview .set-viewport-height{
        position: fixed;
        /*max-height: 93vh;*/
        max-width: 43.66667%;
        width: 43.66667%;
        /* overflow: auto; */
    }
    .files-preview iframe,
    .files-preview .file{
        min-height: 90vh;
    }
    .files-preview .file{
        /*max-height: 93vh;*/
        overflow: auto;
    }
    .files-preview .document-viewer-pagination-coontainer+.file iframe,
    .files-preview .document-viewer-pagination-coontainer+.file{
        /*min-height: 85vh;*/
    }
    .files-preview .document-viewer-pagination-coontainer+.file{
        /*max-height: 86vh;*/
    }
    .files-preview .pg-viewer-wrapper{
        height: auto;
        overflow: auto;
    }
    .files-preview .photo-viewer-container{
        display: block;
    }
    .files-preview .photo-viewer-container img{
        padding: 2px 6px;
    }
}

.document-viewer-pagination-coontainer {
    padding: 8px;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
}

.modal-digitise .modal-content {
    box-shadow: unset !important;
}

/* .document-viewer-pagination-coontainer p {
  text-align: center;
} */

.document-viewer-pagination li {
    display: inline-block;
    border-radius: 4px;
    color: grey;
    margin: 0 7px;
    font-weight: 300;
    border: 1px solid transparent;
}

.document-viewer-pagination li a{
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
}

.document-viewer-pagination li.active {
    color: white;
    background-color: #1e73be;
}

.document-viewer-pagination li.disabled {
    visibility: hidden;
}


.document-viewer-pagination li.previous,
.document-viewer-pagination li.next{
    border-color: #d5d5d5;
}

.modal-digitise .modal-fullscreen .modal-content{
    height: unset !important;
}

/* end, files preview section */

.vh-85{
    height: 85vh!important
}

.vh-90{
    height: 90vh!important
}

.message-list{
    height: 65vh !important;
    overflow-y: scroll;
}

.conversation-item:hover{
    background-color: #eff2f5!important;
}

.rce-container-mbox {
    padding: 10px;
}

.rce-mbox {
    max-width: 60%;
    border-radius: .475rem!important;
    padding: 1.25rem!important;
    position: unset !important;
    box-shadow: unset !important;
    background-color: #f8f5ff!important;
}

.rce-mbox-right {
    background-color: #f1faff!important;
}

.rce-mbox-text {
    color: #181c32!important;
    font-weight: 500!important;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.rce-mbox-time {
    bottom: -15px !important;
}

.rce-mbox-right-notch {
    display: none !important;
}

.Toastify__toast-container {
    word-break: break-word;
}

.select-component__menu {
    min-width: 180px;
    position: relative !important;
    top: 0px !important;
    overflow: hidden;
}

.custom-async-select__container{
    width: 100% !important;
    min-width: 100% !important;
    background-color: #F3F6F9 !important;
    border: 1px solid #E5EAEE !important;
    color: #3F4254 !important;
    border-radius: 0.42rem !important;
    font-size: 1rem !important;
}

.custom-async-select__control{
    background-color: unset !important;
    border-color: unset !important;
    border-radius: unset !important;
    border-style: unset !important;
}

.custom-async-select__menu{
    max-width: 100% !important;
}

.label {
    padding: 0;
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 0.8rem;
    background-color: #EBEDF3;
    color: #3F4254;
    font-weight: 400;
    height: 20px;
    width: 20px;
    font-size: 0.8rem;
}

.label.label-danger {
    color: #ffffff;
    background-color: #F64E60;
}

.shimmer {
    background-color: #f6f7f8 !important;
    background-image: linear-gradient(
            to right,
            #f6f7f8 0%,
            #edeef1 20%,
            #f6f7f8 40%,
            #f6f7f8 100%
    ) !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: kanbanItemCardShimmer;
    animation-timing-function: linear;

    min-height: 46px;
}

.sub-modal {
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.15s linear;
}

.MuiTooltip-tooltip {
    font-size: 13px !important;
}

.subject {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.hide-dropdown::after {
    content: unset!important;
}

.btn-outline-white,
.btn-outline-white:hover {
    border: 1px solid white !important;
}

.btn-outline-dark,
.btn-outline-dark:hover {
    border: 1px solid black !important;
}

.bg-woo-commerce {
    background-color: #7f54b3;
}

.bg-amazon {
    background-color: #f90;
}

.bg-shopline {
    background-color: #00142D;
}

.input-group-append, .input-group-prepend {
    display: flex;
}

.input-group-prepend {
    margin-right: -1px;
}

.switch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
}
.switch label {
    margin: 0;
}
.switch input:empty {
    margin-left: -999px;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;
}
.switch input:empty ~ span {
    display: inline-block;
    position: relative;
    float: left;
    width: 1px;
    text-indent: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    content: " ";
    -webkit-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
}
.switch input[disabled] {
    cursor: not-allowed;
}
.switch input[disabled] ~ span:after,
.switch input[disabled] ~ span:before {
    cursor: not-allowed;
    opacity: 0.5;
}
.switch.switch-icon input:checked ~ span:after {
    font-family: Ki;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "";
    line-height: 0;
}

.switch input:empty ~ span {
    margin: 2px 0;
    height: 30px;
    width: 57px;
    border-radius: 15px;
}
.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
    width: 54px;
    border-radius: 15px;
}
.switch input:empty ~ span:after {
    height: 24px;
    width: 24px;
    top: 3px;
    bottom: 3px;
    margin-left: 3px;
    font-size: 0.65em;
    text-align: center;
    vertical-align: middle;
}
.switch input:checked ~ span:after {
    margin-left: 26px;
}
.switch.switch-sm input:empty ~ span {
    margin: 2px 0;
    height: 24px;
    width: 40px;
    border-radius: 12px;
}
.switch.switch-sm input:empty ~ span:before,
.switch.switch-sm input:empty ~ span:after {
    width: 38px;
    border-radius: 12px;
}
.switch.switch-sm input:empty ~ span:after {
    height: 20px;
    width: 20px;
    top: 2px;
    bottom: 2px;
    margin-left: 2px;
    font-size: 0.55em;
    text-align: center;
    vertical-align: middle;
}
.switch.switch-sm input:checked ~ span:after {
    margin-left: 16px;
}
.switch.switch-lg input:empty ~ span {
    margin: 2px 0;
    height: 40px;
    width: 75px;
    border-radius: 20px;
}
.switch.switch-lg input:empty ~ span:before,
.switch.switch-lg input:empty ~ span:after {
    width: 72px;
    border-radius: 20px;
}
.switch.switch-lg input:empty ~ span:after {
    height: 34px;
    width: 34px;
    top: 3px;
    bottom: 3px;
    margin-left: 3px;
    font-size: 0.75em;
    text-align: center;
    vertical-align: middle;
}
.switch.switch-lg input:checked ~ span:after {
    margin-left: 34px;
}

.switch input:empty ~ span:before {
    background-color: #EBEDF3;
}
.switch input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch input:checked ~ span:before {
    background-color: #EBEDF3;
}
.switch input:checked ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #3699FF;
}
.switch.switch-primary:not(.switch-outline) input:empty ~ span:before {
    background-color: #3699FF;
}
.switch.switch-primary:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-primary:not(.switch-outline) input:checked ~ span:before {
    background-color: #3699FF;
}
.switch.switch-primary:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #3699FF;
    background-color: #ffffff;
}
.switch.switch-outline.switch-primary input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-primary input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-primary input:checked ~ span:before {
    border: 2px solid #3699FF;
    background-color: transparent;
}
.switch.switch-outline.switch-primary input:checked ~ span:after {
    color: #ffffff;
    background-color: #3699FF;
}
.switch.switch-secondary:not(.switch-outline) input:empty ~ span:before {
    background-color: #E4E6EF;
}
.switch.switch-secondary:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-secondary:not(.switch-outline) input:checked ~ span:before {
    background-color: #E4E6EF;
}
.switch.switch-secondary:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #E4E6EF;
    background-color: #ffffff;
}
.switch.switch-outline.switch-secondary input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-secondary input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-secondary input:checked ~ span:before {
    border: 2px solid #E4E6EF;
    background-color: transparent;
}
.switch.switch-outline.switch-secondary input:checked ~ span:after {
    color: #ffffff;
    background-color: #E4E6EF;
}
.switch.switch-success:not(.switch-outline) input:empty ~ span:before {
    background-color: #1BC5BD;
}
.switch.switch-success:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-success:not(.switch-outline) input:checked ~ span:before {
    background-color: #1BC5BD;
}
.switch.switch-success:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #1BC5BD;
    background-color: #ffffff;
}
.switch.switch-outline.switch-success input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-success input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-success input:checked ~ span:before {
    border: 2px solid #1BC5BD;
    background-color: transparent;
}
.switch.switch-outline.switch-success input:checked ~ span:after {
    color: #ffffff;
    background-color: #1BC5BD;
}
.switch.switch-info:not(.switch-outline) input:empty ~ span:before {
    background-color: #8950FC;
}
.switch.switch-info:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-info:not(.switch-outline) input:checked ~ span:before {
    background-color: #8950FC;
}
.switch.switch-info:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #8950FC;
    background-color: #ffffff;
}
.switch.switch-outline.switch-info input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-info input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-info input:checked ~ span:before {
    border: 2px solid #8950FC;
    background-color: transparent;
}
.switch.switch-outline.switch-info input:checked ~ span:after {
    color: #ffffff;
    background-color: #8950FC;
}
.switch.switch-warning:not(.switch-outline) input:empty ~ span:before {
    background-color: #FFA800;
}
.switch.switch-warning:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-warning:not(.switch-outline) input:checked ~ span:before {
    background-color: #FFA800;
}
.switch.switch-warning:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #FFA800;
    background-color: #ffffff;
}
.switch.switch-outline.switch-warning input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-warning input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-warning input:checked ~ span:before {
    border: 2px solid #FFA800;
    background-color: transparent;
}
.switch.switch-outline.switch-warning input:checked ~ span:after {
    color: #ffffff;
    background-color: #FFA800;
}
.switch.switch-danger:not(.switch-outline) input:empty ~ span:before {
    background-color: #F64E60;
}
.switch.switch-danger:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-danger:not(.switch-outline) input:checked ~ span:before {
    background-color: #F64E60;
}
.switch.switch-danger:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #F64E60;
    background-color: #ffffff;
}
.switch.switch-outline.switch-danger input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-danger input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-danger input:checked ~ span:before {
    border: 2px solid #F64E60;
    background-color: transparent;
}
.switch.switch-outline.switch-danger input:checked ~ span:after {
    color: #ffffff;
    background-color: #F64E60;
}
.switch.switch-light:not(.switch-outline) input:empty ~ span:before {
    background-color: #F3F6F9;
}
.switch.switch-light:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-light:not(.switch-outline) input:checked ~ span:before {
    background-color: #F3F6F9;
}
.switch.switch-light:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #F3F6F9;
    background-color: #ffffff;
}
.switch.switch-outline.switch-light input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-light input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-light input:checked ~ span:before {
    border: 2px solid #F3F6F9;
    background-color: transparent;
}
.switch.switch-outline.switch-light input:checked ~ span:after {
    color: #ffffff;
    background-color: #F3F6F9;
}
.switch.switch-dark:not(.switch-outline) input:empty ~ span:before {
    background-color: #181C32;
}
.switch.switch-dark:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-dark:not(.switch-outline) input:checked ~ span:before {
    background-color: #181C32;
}
.switch.switch-dark:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #181C32;
    background-color: #ffffff;
}
.switch.switch-outline.switch-dark input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-dark input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-dark input:checked ~ span:before {
    border: 2px solid #181C32;
    background-color: transparent;
}
.switch.switch-outline.switch-dark input:checked ~ span:after {
    color: #ffffff;
    background-color: #181C32;
}
.switch.switch-white:not(.switch-outline) input:empty ~ span:before {
    background-color: #ffffff;
}
.switch.switch-white:not(.switch-outline) input:empty ~ span:after {
    background-color: #ffffff;
    opacity: 0.7;
}
.switch.switch-white:not(.switch-outline) input:checked ~ span:before {
    background-color: #ffffff;
}
.switch.switch-white:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #ffffff;
    background-color: #ffffff;
}
.switch.switch-outline.switch-white input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent;
}
.switch.switch-outline.switch-white input:empty ~ span:after {
    background-color: #EBEDF3;
}
.switch.switch-outline.switch-white input:checked ~ span:before {
    border: 2px solid #ffffff;
    background-color: transparent;
}
.switch.switch-outline.switch-white input:checked ~ span:after {
    color: #ffffff;
    background-color: #ffffff;
}

.flatpickr-wrapper {
    width: 100%;
}
